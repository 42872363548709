import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const TermsPage = () => (
  <Layout>
    <SEO title="Terms Of Use" />
    <div className={'container'}>
      <div className={'content'}>
        <div className={'title'}>
          <h1>Terms Of Use</h1>
        </div>

        <p>
          <strong>Last Updated 07 June 2019</strong>
        </p>

        <h3>Summary</h3>
        <ol>
          <li>
            You subscribe to our services for a specific term (annual,
            half-yearly, quarterly, or monthly), and your subscription gets
            renewed automatically at the end of each term.
          </li>
          <li>
            If you wish to cancel your account, you can do so from Shopify
            console. Upon cancellation, your data is processed as per Shopify terms.
          </li>
          <li>
            Loopclub can terminate the contract by giving 2 weeks notice. In
            such cases fund will be processed as per the guidelines of Shopify.
          </li>
          <li>
            If you use our services, we can use your business logo on our
            websites for promotional purposes.
          </li>
        </ol>
        <h2>
          <span>Terms of Service</span>
        </h2>
        <p>
          <span>
            This agreement is entered between Loopclub, having its office in
            Bangalore, India, hereinafter referred to as "Company/we/Loopclub"
            and the payer or/and the recipient of services hereunder identified
            as part of the subscription process for our Services, hereinafter
            referred to as “You” or “Customer”.
            <br />{' '}
          </span>
          Please understand that by accepting these terms and conditions,
          Customer represents and warrants that Customer is major and thus
          legally capable to enter into a contract and in case Customer is
          acting on behalf of some business entity, Customer is duly authorized
          to enter into agreement on behalf of the entity Customer is
          representing. Customer also represents and warrants that Customer is
          not a competitor of Loopclub.
          <br /> Therefore, by clicking “I agree”, ordering, and/or using
          Loopclub's services, Customer agrees to be bound by all of the terms
          and conditions of this agreement (hereinafter referred to as the
          agreement).
        </p>
        <p>
          Now whereas Customer and Company both hereby agree to the terms &amp;
          conditions hereinafter mentioned:
        </p>
        <h3>
          <span>1. Scope of Service</span>
        </h3>
        <p>
          <span>
            Loopclub offers Recommendation Kit services (the Service) which
            includes the following:
            <br />{' '}
          </span>
          Shopify Embedded Interface (the “Dashboard”). The service is hosted at
          the domain managed by Loopclub. Loopclub will offer Technical Support
          for the Service. For any issue relating to the Service, Customer may
          contact Loopclub's technical support by sending an email to{' '}
          <a href="mailto:support@loopclub.io">support@loopclub.io.</a>
          <br /> Only Customer or Customer’s authorized user(s) may contact
          Loopclub's technical support.
        </p>
        <h3>
          <span>2. Free Trial</span>
        </h3>
        <p>
          <span>
            If a Customer registers for a free trial of the Services, Loopclub
            will make the Services available on a trial basis and free of charge
            to the Customer until the earlier of <br />{' '}
          </span>
        </p>
        <ol>
          <li>
            the end of the free trial period or the start date of Customer’s
            subscription.
          </li>
          <li>
            If Loopclub includes additional terms and conditions on the trial
            registration Web page, those will apply as well. During the free
            trial period,
            <ol style={{ listStyleType: 'lower-roman' }}>
              <li>
                the Services are provided “as is” and without a warranty of any
                kind,
              </li>
              <li>
                Loopclub may suspend, limit, or terminate the Services for any
                reason at any time without notice, and
              </li>
              <li>
                Loopclub will not be liable toward Customer for damages of any
                kind related to Customer’s use of the Services. Unless Customer
                subscribes to the Services before the end of the free trial, all
                of Customer’s data on the Service may be permanently deleted at
                the end of the trial and Loopclub may not be able to recover it.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <span>
            It is hereby clarified that the Recommendation Kit Beta Product or
            Service may contain bugs, errors, omissions, and other problems; and
            Loopclub will not provide any support and maintenance for its beta
            Recommendation Kit Products or Services. The Recommendation Kit Beta
            Product or Service may not operate correctly and may be
            substantially modified prior to first commercial availability, or
            may be withdrawn at any time. Although Loopclub will make best
            possible efforts to intimate users of Recommendation Kit Beta
            Product or Service about any modification or termination of the
            Recommendation Kit Beta Product or Service ahead of time, Loopclub
            reserves the right to modify or terminate the Recommendation Kit
            Beta Product or Service and Customer’s access to the Recommendation
            Kit Beta Product or Service for any reason, without notice, at any
            time, and without any liability to Customer. Once the Recommendation
            Kit Beta Product or Service is terminated, Loopclub will not be
            obliged to provide continued access to data collected during the
            testing period. However, Loopclub shall delete the data collected
            during the Beta phase upon receipt of a written request for deletion
            of such data by Customer.
          </span>
        </p>
        <h3>
          <span>3. Lawful use of the services</span>
        </h3>
        <p>
          <span>
            Customer hereby agrees to use the Services of Loopclub only in an
            authorized manner as per the terms of this Agreement. In case it is
            found that Customer’s use of Services violates the terms of this
            Agreement or any other law, rule or regulation enacted by the
            concerned authorities from time to time, Loopclub reserves the right
            to terminate the Agreement with immediate effect.
          </span>
        </p>
        <h3>
          <span>
            4. Loopclub's Responsibilities, Representations, and Warranties
          </span>
        </h3>
        <ol>
          <li>
            <span>In the performance of Services, Loopclub agrees to:</span>
            <ol style={{ listStyleType: 'lower-roman' }}>
              <li>
                perform the Services to the best of its ability and with the
                degree of care, diligence and skill that a reasonably prudent
                person would exercise in comparable circumstances;
              </li>
              <li>
                <span>
                  liaise with Customer through Customer’s coordinator on matters
                  related to the use of, and the identification and resolution
                  of errors in the Service; however, this shall not include the
                  provision of training services;&nbsp;
                </span>
              </li>
              <li>
                <span>
                  proceed according to Customer’s reasonable instructions for
                  the disposition of Customer’s data and supplies on the
                  termination of the Agreement.
                </span>
              </li>
            </ol>
          </li>
        </ol>
        <h3>
          <span>
            5. Customer’s Responsibilities, Representations, and Warranties
          </span>
        </h3>
        <ol>
          <li>
            <span>
              <strong>Customer agrees to</strong>:
            </span>
            <ol>
              <li>
                <span>
                  provide all necessary data and any special forms or other
                  required materials or information to Loopclub on schedule or
                  in a timely manner to enable Loopclub to provide the Services;
                </span>
              </li>
              <li>
                <span>
                  ensure accuracy, legibility and completeness of all data
                  supplied to Loopclub and be solely responsible for the results
                  obtained from Customer’s use of any of the Services;
                </span>
              </li>
              <li>
                <span>
                  liaise with Loopclub through a coordinator Customer will
                  identify, on matters related to the Services, and authorize
                  that coordinator to make decisions on behalf of Customer in
                  relation to the implementation of this Agreement and the
                  Services and any changes thereto; and&nbsp;
                </span>
              </li>
              <li>
                <span>
                  control, and be responsible for the use of, account
                  information, user ids, and passwords related to the Services,
                  where required
                </span>
              </li>
            </ol>
          </li>
          <li>
            <span>
              <strong>Customer Representations:</strong>{' '}
              <strong>
                Customer represents and warrants to Loopclub that:
              </strong>
            </span>
            <ol>
              <li>
                the information Customer has provided for the purpose of
                establishing an account with Loopclub is accurate.
              </li>
              <li>
                <span>
                  Customer has complied with and will continue to comply with
                  all applicable laws, including privacy laws, and has obtained
                  and will continue to obtain the requisite privacy consents in
                  the collection and use of all information that may be
                  collected on any website or maintained on any server hosted by
                  Loopclub.
                </span>
              </li>
              <li>
                <span>
                  Using the Services, Customer shall not collect and/or store
                  any information which can be recognized by Loopclub as
                  personally identifiable information other than as Agreed in
                  the data protection Agreement if any signed between Customer
                  and Loopclub. If Customer wants to store any personal
                  information, it needs to be encrypted and hashed at Customer’s
                  end.
                </span>
              </li>
            </ol>
          </li>
        </ol>
        <h3>
          <span>6. Term, Termination, and Suspension of Service</span>
        </h3>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li>
            <strong>
              <span>
                Initial Term:
                <br />{' '}
              </span>
            </strong>
            <span>
              The initial subscription term shall begin on the effective date of
              Customer subscription and expire at the end of the period selected
              during the subscription process.
            </span>
          </li>
          <li>
            <span>
              <strong>Renewal of Term</strong>:<br />{' '}
            </span>
            <span>
              Unless one of us deletes the app from Shopify, the paid
              subscription and this Agreement will automatically renew for the
              period selected by the customer in its latest term (“Renewal
              Subscription Term”). The Renewal Subscription Term will be on the
              current terms and conditions of this Agreement, and subject to the
              renewal pricing provided in our standard pricing available at{' '}
              <a href="https://recommendations.loopclub.com">
                <span>https://recommendations.loopclub.com</span>
              </a>
              . Should you decide not to renew, you may send the notice of
              non-renewal by email to{' '}
              <a href="mailto:support@loopclub.io">support@loopclub.io</a> or
              use the cancellation option within the Shopify interface.
            </span>
          </li>
          <li>
            <span>
              <strong>Termination/Suspension by Loopclub</strong>:<br />{' '}
            </span>
            <span>
              Loopclub may terminate this Agreement or suspend the Services
              before the end of the Term without liability:
              <br />{' '}
            </span>
            <p></p>
            <ol style={{ listStyleType: 'lower-roman' }}>
              <li>
                On 2 weeks' notice to Customer, if Customer is overdue on the
                payment of any amount due under this Agreement.
              </li>
              <li>
                If Customer materially violates any other provision of this
                Agreement and fails to cure the violation within 10 days’ notice
                in writing from Loopclub, describing the violation in reasonable
                detail; or
              </li>
              <li>
                Immediately on written notice upon Customer becoming insolvent
                or bankrupt within the meaning of the Bankruptcy and Insolvency
                Laws.
              </li>
            </ol>
            <p>
              During suspension, Customer will not be able to access the
              Services. Loopclub will use commercially reasonable efforts to
              give Customer an advance notice in writing of suspension of
              Service unless a law enforcement or governmental agency directs
              otherwise or suspension without notice is necessary to protect
              Loopclub or its other customers.
            </p>
          </li>
          <li>
            <span>Following termination:</span>
            <span>
              It is agreed that in case of termination, the fees owed to
              Loopclub as per this Agreement will not be cancelled or waived.
              Customer’s data and account settings shall be irrevocably deleted
              within 30 days from the date of termination. It shall be
              Customer’s exclusive responsibility to secure all necessary data
              from Customer’s account prior to termination.
            </span>
          </li>
        </ol>
        <h3>
          <span>7. Fees, Billing, Taxes, Charges</span>
        </h3>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li>
            <strong>
              <span>
                Fees:
                <br />{' '}
              </span>
            </strong>
            <span>
              The fees set forth in the order form created at the outset of
              Customer’s account shall be effective for the Initial Term and
              each renewal Term of this Agreement, provided that Loopclub shall
              have the right to revise these fees at any time upon thirty (30)
              days’ written notice to Customer. In the event that Customer does
              not agree with such fee revision, Customer shall have the right to
              terminate this Agreement upon 2 weeks' written notice, provided
              that such notice of termination must be received within thirty
              (30) days from the date of notice of fee increase.
            </span>
          </li>
          <li>
            <strong>
              <span>
                Billing and Payment Arrangements:
                <br />{' '}
              </span>
            </strong>
            <span>
              Loopclub will bill Customer on an
              annual/monthly/quarterly/half-yearly or any other mutually agreed
              period basis for all recurring fees. The payments will be made in
              accordance with terms recommended by Shopify.
            </span>
          </li>
          <li>
            <strong>
              <span>
                Taxes:
                <br />
              </span>
            </strong>
            <span>
              Customer acknowledges that all applicable taxes, duties, or
              government levies whatsoever are not included in the fees and
              expenses charged under this Agreement. Customer will make timely
              payment of all such taxes, duties, or government levies related to
              this Agreement.
            </span>
          </li>
        </ol>
        <h3>
          <span>8. Modification of Terms and Conditions</span>
        </h3>
        <p>
          <span>
            Loopclub may update, amend, modify, or supplement the terms and
            conditions of this Agreement from time to time and will use
            reasonable efforts to notify Customer regarding the same. Customer
            is responsible for regularly reviewing the most current version of
            this Agreement at any time here. If at any time Customer does not
            agree with any amendment, modification or supplement to the terms
            and conditions of this Agreement, Customer may terminate this
            Agreement for convenience, as per Clause 6 mentioned aforesaid.
            Customer’s continued use of Customer’s account and/or the services
            after the notice period will be conclusively deemed as acceptance by
            Customer of any such modifications or amendment.
          </span>
        </p>
        <h3>
          <span>9. Limited Warranty: Limitation of Damages</span>
        </h3>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li>
            <span>
              Loopclub provides the Service “as is”. Customer expressly agrees
              that use of the Service is at Customer’s sole risk. Loopclub and
              its subsidiaries, affiliates, officers, employees, agents,
              partners, vendors, and licensors expressly disclaim all warranties
              of any kind, whether express or implied, including, but not
              limited to the implied warranties of merchantability, fitness for
              a particular purpose, and non-infringement. Customer hereby agrees
              that the terms of this Agreement shall not be altered due to
              custom or usage or due to the parties’ course of dealing or course
              of performance under this Agreement.
            </span>
          </li>
          <li>
            <span>
              Loopclub and its subsidiaries, affiliates, officers, employees,
              agents, partners, vendors, and licensors shall not be liable for
              any indirect, incidental, special, punitive, or consequential
              damages, including but not limited to damages for lost profits,
              business interruption, loss of programs or information, and the
              like, that result from the use or inability to use the Service or
              from mistakes, omissions, interruptions, deletion of files or
              directories, errors, defects, delays in operation, or
              transmission, regardless of whether Loopclub has been advised of
              such damages or their possibility.
            </span>
          </li>
          <li>
            <span>
              Customer is fully responsible for the content of the information
              and data passing through Loopclub network or using the Services
              and for all activities that Customer conducts with the assistance
              of such Services.
            </span>
          </li>
          <li>
            <span>
              Notwithstanding anything to the contrary contained in this
              Agreement, Loopclub's aggregate liability under or in connection
              with the Agreement, whether arising from contract, negligence, or
              otherwise, shall in any event not exceed the amount paid by
              Customer under the Agreement in the preceding 6 months.
            </span>
          </li>
        </ol>
        <h3>
          <span>10. Software and Intellectual Property Rights</span>
        </h3>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li>
            <strong>
              <span>
                Ownership of Intellectual Property Rights:
                <br />{' '}
              </span>
            </strong>
            <span>
              All Intellectual Property Rights, including any Software, owned by
              a party, its licensors or subcontractors as on the effective date
              of this Agreement shall continue to be owned by such party, its
              licensors or subcontractors and, except as expressly provided in
              this Agreement, the other party shall not acquire any right, title
              or interest in or to such Intellectual Property Rights. Loopclub
              shall own all rights, titles, and interests in and to any
              materials created or developed by Loopclub or its subcontractors
              for its internal use or for assisting Customer in the provision of
              the Services; and Customer shall own all rights, titles, and
              interests in and to any Intellectual Property Rights resulting or
              based on any work product created or developed exclusively for
              Customer under this Agreement, if fully paid for by Customer.
            </span>
          </li>
          <li>
            <strong>
              <span>
                Right to use logo:
                <br />{' '}
              </span>
            </strong>
            <span>
              Customer agrees to let Loopclub use its organization’s logo in
              Loopclub's customer list and at other places on its website
              (including but not limited to Recommendation Kit) and promotional
              materials, including press releases. This clause will survive
              expiry or termination of this Agreement.
            </span>
          </li>
          <li>
            <strong>
              <span>
                License of Customer Software and Intellectual Property:
                <br />{' '}
              </span>
            </strong>
            <span>
              Customer agrees to grant to Loopclub, solely for Loopclub’s
              provision of the Services, access to any tool or application used
              by Customer and required by Loopclub in order to troubleshoot and
              perform its Services, license during the Term to use any
              Intellectual Property Rights, including any Software, owned by or
              licensed to Customer by third parties and that is necessary for
              providing the Services to Customer and otherwise, performing its
              obligations under this Agreement. With respect to any Intellectual
              Property Rights and Software used by Loopclub to provide the
              Services, Customer represents and warrants that: (a) Customer is
              either the owner of such Intellectual Property Rights or Software
              or is authorized by its owner to include it under this Agreement;
              and (b) Loopclub has the right during the Term to use such
              Intellectual Property Rights and Software for the purpose of
              providing the Services to Customer as contemplated by this
              Agreement.
            </span>
          </li>
          <li>
            <strong>
              <span>
                No Assurance of Compatibility:
                <br />{' '}
              </span>
            </strong>
            <span>
              Customer acknowledges that Loopclub makes no representation,
              warranty, or assurance that Customer’s equipment and software will
              be compatible with Loopclubs equipment, software and systems, or
              the Services.
            </span>
          </li>
        </ol>
        <h3>
          <span>11. Confidentiality</span>
        </h3>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li>
            <span>
              Loopclub will not use any of Customer’s Confidential Information
              except in connection with the performance of the Services or the
              exercise of its rights under this Agreement and will take all
              reasonable precautions to maintain the confidentiality of
              Customer’s Confidential Information and to prevent unauthorized
              disclosure to others of the Confidential Information. Loopclub
              shall implement industry-standard security procedures, such as
              appropriate firewall, encryption and access security measures to
              safeguard Confidential Information.
            </span>
          </li>
          <li>
            <span>
              Loopclub shall only disclose Confidential Information to those of
              its employees and permitted agents and subcontractors who have a
              need to know and require access to the Confidential Information as
              may be reasonably necessary in exercising Loopclub’s rights and
              performance of the Services under this Agreement. Notwithstanding
              anything contrary to this Agreement, Loopclub will not be required
              to keep confidential, and may use or license without restriction,
              any ideas, concepts, know-how or techniques related to information
              processing which are developed by Loopclub in the performance of
              Services.
            </span>
          </li>
          <li>
            <span>
              Notwithstanding the foregoing, Loopclub shall be permitted to:
            </span>
            <ol style={{ listStyleType: 'lower-roman' }}>
              <li>
                <span>monitor Customer’s use of the Services;</span>
              </li>
              <li>
                <span>
                  report to the appropriate authorities any conduct by Customer
                  (or Customer’s customers or end users) that Loopclub
                  reasonably believes violates any applicable law;
                </span>
              </li>
              <li>
                <span>
                  provide any information, including Confidential Information,
                  required by law or regulation to be disclosed, or in response
                  to a formal or informal request from a law enforcement or
                  government agency; and
                </span>
              </li>
              <li>
                <span>
                  Disclose Customer’s name with its other customers as reference
                  or as part of case studies and testimonials that Loopclub is
                  providing the Services to Customer at such places as Loopclub
                  may deem fit.
                </span>
              </li>
            </ol>
          </li>
        </ol>
        <h3>12. Indemnification</h3>
        <p>
          <span>
            Parties shall indemnify, defend, and hold harmless each other (and
            their subsidiaries, affiliates, officers, employees, agents,
            partners, mandatories, vendors, and licensors) of any and all Claims
            (including third-party Claims) arising as a result of or in relation
            to any breach of this Agreement or fault by the other party.
            Customer shall indemnify Loopclub in relation to any activities
            conducted by Customer through the Services, or otherwise in relation
            to “Customer’s” products or services.
          </span>
        </p>
        <h3>
          <span>13. Governing Law</span>
        </h3>
        <p>
          <span>
            This Agreement shall be governed by and construed in accordance with
            the laws of India. Customer agrees, in the event any claim or suit
            is brought in connection with this Agreement, it shall be brought to
            the exclusive jurisdiction and venue of the courts of Bangalore,
            India. In any action to enforce this Agreement, including, without
            limitation, any action by Loopclub for the recovery of fees due
            hereunder, Customer shall pay reasonable attorney’s fees and costs
            in connection with such action.
          </span>
        </p>
        <h3>
          <span>14. Severability</span>
        </h3>
        <p>
          <span>
            In the event that any one or more of the provisions contained herein
            shall, for any reason, be held to be invalid, illegal, or
            unenforceable in any respect, such invalidity, illegality, or
            unenforceability shall not affect any of the other provisions of
            this Agreement; and this Agreement shall be construed as if such
            provision(s) had never been contained herein, provided that such
            provision(s) shall be curtailed, limited, or eliminated only to the
            extent necessary to remove the invalidity, illegality, or
            unenforceability.
          </span>
        </p>
        <h3>
          <span>15. Waiver</span>
        </h3>
        <p>
          <span>
            No waiver by Loopclub of any breach by Customer of any of the
            provisions of this Agreement shall be deemed a waiver of any
            preceding or succeeding breach of this Agreement. No such waiver
            shall be effective unless it is in writing signed by the parties
            hereto and then only to the extent expressly set forth in such
            writing.
          </span>
        </p>
        <h3>
          <span>16. Assignment</span>
        </h3>
        <p>
          <span>
            Neither party may assign or transfer this Agreement or any rights or
            obligations hereunder, in whole or in part, except with the prior
            written consent of the other party, which shall not be withheld
            unreasonably; provided that Loopclub may assign or transfer this
            Agreement, or any rights or obligations hereunder, in whole or in
            part:
          </span>
        </p>
        <ol style={{ listStyleType: 'lower-roman' }}>
          <li>to an affiliate of Loopclub; or</li>
          <li>
            in connection with a merger, amalgamation, or sale of all or a
            substantial part of the business of Loopclub, which assignments
            and/or transfers shall operate novation and discharge Loopclub
            hereunder. A change of control of Customer shall be deemed to be an
            assignment and transfer hereunder and shall be governed by the
            requirements of this provision.
          </li>
        </ol>
        <p>
          The terms and conditions along with privacy policies with all
          references, constitute the sole and entire Agreement of the parties to
          this Agreement with respect to the subject matter contained herein,
          and supersede all prior terms and conditions which were agreed by
          Customer.
        </p>
      </div>
    </div>
  </Layout>
)

export default TermsPage
